import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-responsive-modal';

const LoginWarning = () => {
  const [isOpened, setOpened] = useState(false);
  const { t } = useTranslation('auth');
  return (
    <>
      {' '}
      <div className="text-right">
        <button
          type="button"
          onClick={() => setOpened(true)}
          className="text-gray-500 underline mt-1 text-sm"
        >
          {t('Pourquoi ne reçois-je pas d’email ?')}
        </button>
      </div>
      {isOpened && (
        <Modal
          open
          onClose={() => setOpened(false)}
          classNames={{ modal: 'w-[450px]' }}
          center
        >
          <div className="text-gray-500 text-sm">
            <div className="mb-8 font-bold text-xl text-darkblue">
              {t('Pourquoi ne reçois-je pas d’email ?')}
            </div>

            <div>
              {t(
                'Ce service est ouvert uniquement aux clients Qonto détenteurs de cartes X ou Plus.'
              )}
            </div>

            <div className="mt-5 font-bold text-darkblue text-lg leading-none">
              {t('Si vous êtes client Qonto avec une carte One :')}
            </div>
            <div>
              {t(
                'Vous ne pourrez malheureusement pas accéder à ce service et ne recevrez aucun email avant de changer de forfait.'
              )}
            </div>

            <div className="mt-5 font-bold text-darkblue text-lg leading-none">
              {t(
                'Si vous êtes client Qonto avec une carte X ou Plus, mais que vous ne recevez toujours pas d’e-mail de connexion :'
              )}
            </div>
            <div>{t('Vous pouvez :')} </div>
            <div>
              -{' '}
              {t(
                'vous assurer qu’il n’y a pas de faute de frappe lors de la saisie de votre email'
              )}
            </div>
            <div>
              - {t('utiliser un e-mail valide auquel vous pouvez accéder')}{' '}
            </div>

            <div>- {t('vérifier votre dossier spam')} </div>

            <div className="mt-5">
              {t('Si toujours rien ne fonctionne, contactez-nous.')}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default LoginWarning;
