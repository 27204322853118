import { defaultComponentMapper, Form } from '@caarl_assurance/front-lib';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useUser } from '../components/auth/hooks';
import AuthHeader from '../components/structure/AuthHeader';
import BlankScreen from '../components/structure/BlankScreen';
import LoginWarning from '../components/utils/LoginWarning';
import loginSchema, { loginData } from '../schemas/auth/login';
import { useAxios } from '../utils/hooks';
import { forEachObj } from '../utils/misc';
import { phraseAsNewLine } from '../utils/react';

const LoginPage = () => {
  const { t } = useTranslation(['common', 'auth']);
  const tAuth = (message) => t(message, { ns: 'auth' });

  const { isRedirected, isLoading } = useUser({
    redirectIfFound: true,
    redirectTo: '/',
  });
  const [isFormLoading, setFormLoading] = useState(false);
  const [error, setError] = useState(null);
  const [passwordlessSent, setPasswordlessSent] = useState(false);
  const router = useRouter();
  const axios = useAxios();

  const { tokenError } = router.query;

  if (isLoading || isRedirected) return <BlankScreen />;

  const onSubmitLogin = async (data: loginData) => {
    setError(false);
    setFormLoading(true);
    try {
      let retour: { status?: string; message?: string; error?: string } = {};

      retour = await axios.post(`/api/login`, {
        email: data.email,
      });
      setFormLoading(false);

      if (retour.status === 'ok') {
        setPasswordlessSent(true);
        return null;
      }
      setError(
        t(
          retour.message ||
            retour.error ||
            'Erreur veuillez ré-essayer ultérieurement'
        )
      );
    } catch (err) {
      const responseData = err.response?.data;
      if (responseData?.error === 'BadParametersError') {
        setFormLoading(false);
        return forEachObj(responseData.errors, tAuth);
      }
      const errorMessage = t(
        responseData?.message ||
          responseData?.error ||
          'Erreur veuillez ré-essayer ultérieurement'
      );
      setError(errorMessage);
    }
    setFormLoading(false);
    return null;
  };

  if (passwordlessSent) {
    return (
      <div className="container-centered">
        <AuthHeader />
        {tAuth(
          'Un lien de connexion vous a été envoyé sur votre adresse email.'
        )}
      </div>
    );
  }

  const nuComponentMapper = {
    ...defaultComponentMapper,
    'login-warning': LoginWarning,
  };

  return (
    <div className="container-centered">
      <AuthHeader />
      <div className="text-grey-500 py-3 my-4 text-sm">{tAuth('subtitle')}</div>
      <div className="text-grey-500 py-3 my-4  text-sm">{tAuth('intro')}</div>
      {tokenError && (
        <div className="my-2 p-4 text-gray-500  text-sm text-center bg-gray-300 bg-opacity-25 rounded-md">
          {tAuth('Votre lien a expiré ou est invalide.')}
          <br />
          {tAuth('Vous devez vous reconnecter.')}
        </div>
      )}
      {error && (
        <div className="my-2 p-4 text-red-500 text-center text-sm bg-red-500 bg-opacity-25 rounded-md">
          {error === true ? (
            <>
              Vos identifiants semblent erronés.
              <br />
              Revérifiez-les et faites une nouvelle tentative.
            </>
          ) : (
            phraseAsNewLine(error)
          )}
        </div>
      )}
      {isFormLoading && (
        <div className="animate-bounce text-darkblue">
          {tAuth('Envoi en cours...')}
        </div>
      )}
      <Form
        schema={loginSchema}
        initialValues={{ usePassword: false }}
        onSubmit={onSubmitLogin}
        className={isFormLoading ? 'disabled' : ''}
        componentMapper={nuComponentMapper}
        actionMapper={{
          translate: (x) => tAuth(x),
          translateErrors: (x) =>
            x.map((err) => ({
              ...err,
              message: err.message ? tAuth(err.message) : undefined,
            })),
        }}
      >
        <div
          className="fixed
    bottom-12
    left-4
    right-4
    text-center 
    xs:static
    xs:mt-16"
        >
          <button type="submit" className="btn w-full">
            {tAuth('Recevoir mon lien')}
          </button>
        </div>
      </Form>
    </div>
  );
};

export async function getServerSideProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'auth'])),
      // Will be passed to the page component as props
    },
  };
}

export default LoginPage;
